import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Spinner from "../components/Spinner/Spinner";
import SpinnerWithoutLogo from "../components/Spinner/SpinnerWithoutLogo";
import { RootState } from "../store/store";
import { BlockBlobClient, newPipeline, RestError } from "@azure/storage-blob";
import { DateFormatOptions, StorageURITypes } from "./enumerators";
import { urlFileStorage } from "./constantVariables";
import axios from "axios";
import { IInspection, IInspectionV2 } from "./Interfaces/InspectionInterfaces";
import { IStructure, IStructureV2 } from "./Interfaces/StructureInterfaces";

export function GetUserToken() {
  const token: string = useSelector(
    (state: RootState) => state.authentication.accessToken
  );
  return token;
}
// Ret

// Returns loading spinner
export function ShowLoading(value: string = "") {
  return (
    <React.Fragment>
      <div className="mt-5">
        <Spinner content={value} />
      </div>
    </React.Fragment>
  );
}
export function ShowLoadingWithoutLogo(message: string = "") {
  return (
    <React.Fragment>
      <div className="mt-5">
        <SpinnerWithoutLogo message={message} />
      </div>
    </React.Fragment>
  );
}

export function ShowServerIsNotAvailable() {
  return (
    <div className="border-radius p-2 mt-2 bg-alert">
      <div className="d-flex justify-content-center">
        <div className="ms-3">
          <h6 className="pt-1">
            <i className="fas fa-exclamation-triangle fa-1x "></i> The server is
            not reachable
          </h6>
        </div>
      </div>
    </div>
  );
}

// Take as paramente the organisation information string and returns an object
export function GetABNObject(data: any) {
  let result = null;
  try {
    let splitArray = data.slice(9);
    result = JSON.parse(splitArray.slice(0, -1));
    return result;
  } catch {
    return result;
  }
}

// Returns a new data array according to the "pageNumber" and "pageSize" parameters
export function Paginate(
  data: Array<any>,
  pageNumber: number,
  pageSize: number
) {
  const startIndex = (pageNumber - 1) * pageSize;
  const dataPaginated = [];
  for (let i = startIndex; i < startIndex + pageSize; i++) {
    if (data[i]) {
      dataPaginated.push(data[i]);
    }
  }
  return dataPaginated;
}

// Returns network error message
export function ShowNetworkErrorMessage(
  refreshButton: boolean = false,
  path: string = ""
) {
  const history = useHistory();
  const refreshPage = (path: string) => {
    history.push(path);
  };
  return (
    <React.Fragment>
      <div className="d-flex justify-content-center">
        <div className="alert text-center bg-alert" role="alert">
          <div>
            <h4>
              <i className="fas fa-exclamation-triangle fa-1x"></i> The server
              is not reachable
            </h4>
          </div>
          <div className="mt-2">
            {!refreshButton && <p>Please try again later.</p>}
            {refreshButton && (
              <p>
                Check your network connection and click in the button below to
                try again.
              </p>
            )}
          </div>

          {refreshButton && path !== "" && (
            <div className="mt-5">
              <button
                type="button"
                className="btn btn-outline-secondary btn-sm text-16 ms-2"
                aria-label="Close"
                onClick={() => refreshPage(path)}
              >
                Refresh page
              </button>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export function ConvertTimeFromUTCToLocalTime(utcString: string) {
  let utcDateTime = new Date(utcString);
  let localDateTime = new Date(
    utcDateTime.getTime() - utcDateTime.getTimezoneOffset() * 60 * 1000
  );

  return [
    localDateTime.toLocaleDateString(DateFormatOptions.enAU),
    localDateTime.toLocaleTimeString(),
  ];
}

// export function FormatDate(dateTimeString: string, removeTime = false) {
//   let dateTemp = dateTimeString.substring(0, dateTimeString.indexOf("T"));
//   if (removeTime) {
//     dateTemp = dateTemp.split("T")[0];
//   }
//   return dateTemp;
// }

export function FormatDate(dateTimeString: string | undefined, removeTime = false) {
  if (!dateTimeString) {
    return ''; // Return an empty string or handle the error as needed
  }

  let dateTemp = dateTimeString.substring(0, dateTimeString.indexOf("T"));
  if (removeTime) {
    dateTemp = dateTemp.split("T")[0];
  }
  return dateTemp;
}


// Returns a string on the format (YYYY-MM-DD => Exam: 2021-09-01)
export function DateTimeToString(date: Date, withTime: boolean = true) {
  let day = date.getUTCDate();
  let month = date.getUTCMonth() + 1;
  let year = date.getUTCFullYear();
  let hour = date.getUTCHours();
  let minutes = date.getUTCMinutes();
  let seconds = date.getUTCSeconds();
  let dateString;
  if (withTime) {
    dateString = `${year}-${month <= 9 ? "0" + month : month}-${
      day <= 9 ? "0" + day : day
    }-${hour <= 9 ? "0" + hour : hour}:${
      minutes <= 9 ? "0" + minutes : minutes
    }:${seconds <= 9 ? "0" + seconds : seconds}`;
  } else {
    dateString = `${year}-${month <= 9 ? "0" + month : month}-${
      day <= 9 ? "0" + day : day
    }`;
  }
  return dateString;
}

export enum FormatType {
  ddmmyyyy = 1,
  yyyymmdd = 2,
}

// Function to format the date
export function FormatDateOptions(
  date: Date,
  localTime: DateFormatOptions = DateFormatOptions.enAU,
  type: FormatType = FormatType.ddmmyyyy
) {
  if (type === 1) {
    // DD/MM/YYYY
    return new Date(date).toLocaleDateString(localTime);
  }
  if (type === 2) {
    // YYYY-MM-DD
    let newDate = new Date(date).toLocaleDateString(localTime);
    let dateArray = newDate.split("/");
    newDate = `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;
    return newDate;
  }
}

export function RoundCeilingToOneDecimalPoint(rating: number | undefined) {
  if (rating !== undefined) {
    return (Math.ceil(rating * 2) / 2).toFixed(1);
  }

  return 0;
}

// Function Get Latest inspection from Structure
export function GetStructureLatestInspection(
  inspections: IInspection[],
  structureId: number
) {
  let inspectionsResult = inspections.filter(
    (inspection: IInspection) => inspection.structureId === structureId
  );
  var inspectionsResultSorted = inspectionsResult
    .sort((a, b) => {
      return (
        new Date(a.inspectionDate).getTime() -
        new Date(b.inspectionDate).getTime()
      );
    })
    .reverse();
  return inspectionsResultSorted[0];
}



export function GetStructureLatestInspectionV2(
  inspections: IInspectionV2[],
  structureId: number
) {
  let inspectionsResult = inspections.filter(
    (inspection: IInspectionV2) => inspection.structureId === structureId
  );
  var inspectionsResultSorted = inspectionsResult
    .sort((a, b) => {
      return (
        new Date(a.inspectionDate).getTime() -
        new Date(b.inspectionDate).getTime()
      );
    })
    .reverse();
  return inspectionsResultSorted[0];
}

// Set strucure lates inspection condition rating
export function SetStructureLatestRating(
  allInspections: IInspection[],
  structures: IStructure[]
) {
  let temp = [...structures];
  temp.map(
    (structure) =>
      (structure.latestInspectionConditionWeightedAverage =
        GetStructureLatestInspection(
          allInspections,
          structure.id
        )?.conditionRatingWeightedAverage)
  );
  return temp;
}


export function SetStructureLatestRatingV2(
  allInspections: IInspectionV2[],
  structures: IStructureV2[]
) {
  let temp = [...structures];
  temp.map(
    (structure) =>
      (structure.condition =
        GetStructureLatestInspectionV2(
          allInspections,
          structure.id
        )?.conditionRatingWeightedAverage)
  );
  return temp;
}




//---------------------------------------------------------------------------------------------------------------------------------------------------

/* AZURE FUNCTIONS*/

export async function azureUpload(sas: any, blob: Blob) {
  const blockBlobClient = new BlockBlobClient(sas, newPipeline());
  return await blockBlobClient.uploadData(blob);
}

export async function azureDownload(sas: any) {
  const blockBlobClient = new BlockBlobClient(sas, newPipeline());
  const response = await blockBlobClient.download();
  return response.blobBody;
}

export async function azureDelete(sas: any) {
  const blockBlobClient = new BlockBlobClient(sas, newPipeline());
  const response = await blockBlobClient.deleteIfExists();
  return response;
}

export async function GetImageSource(uri: string) {
  try {
    let client = new BlockBlobClient(uri);
    const resp = await client.download();
    const imageBlob  = await resp.blobBody;
    const imageUrl = URL.createObjectURL(imageBlob);
    return imageUrl;
  }
  catch (error) {
    if (error instanceof RestError && error.statusCode === 404) {
      return "The Image not found in blob strage"
    } else {
      return "There is an error"
    }
  }

}

export function GetLogoURI(type: number, orgId: number) {
  const my_uri = `${urlFileStorage}/document/logo/${type}/${orgId}`;
  return my_uri;
}

export async function DownloadLogo(organisationId: number, token: string) {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.post(
    GetLogoURI(StorageURITypes.DOWNLOAD_URI, organisationId),
    [],
    config
  );
  const uri = res.data.uris[`${organisationId}`];
  return await azureDownload(uri);
}

export function SortComponents(data: any[], type: number) {
  if (type !== 1 && type !== 2) return data;
  let tempData = [...data];
  tempData = tempData.sort((a: any, b: any) => {
    let A: string = "";
    let B: string = "";
    // Type 1 :  Images data
    if (type === 1) {
      A = a?.component?.organisationComponent.componentNumber
        ?.toLowerCase()
        .replace(/[abcdefghijklmnopqrstuvwxyz]/g, "");
      B = b?.component?.organisationComponent.componentNumber
        ?.toLowerCase()
        .replace(/[abcdefghijklmnopqrstuvwxyz]/g, "");
    }
    // Type 2 :  IComponent interface
    if (type === 2) {
      A = a?.organisationComponent.componentNumber
        ?.toLowerCase()
        .replace(/[abcdefghijklmnopqrstuvwxyz]/g, "");
      B = b?.organisationComponent.componentNumber
        ?.toLowerCase()
        .replace(/[abcdefghijklmnopqrstuvwxyz]/g, "");
    }

    // If number
    if (Number(A) && Number(B)) {
      if (Number(A) < Number(B)) {
        return -1;
      }
      if (Number(A) > Number(B)) {
        return 1;
      }
      return 0;
    }
    // If string
    else {
      if (A < B) {
        return -1;
      }
      if (A > B) {
        return 1;
      }
      return 0;
    }
  });
  return tempData;
}

export function SortComponentsV2(data: any[], type: number) {
  if (type !== 1 && type !== 2) return data;
  let tempData = [...data];
  tempData = tempData.sort((a: any, b: any) => {
    let A: string = "";
    let B: string = "";
    // Type 1 :  Images data
    if (type === 1) {
      A = a?.component?.componentTemplate.componentNumber
        ?.toLowerCase()
        .replace(/[abcdefghijklmnopqrstuvwxyz]/g, "");
      B = b?.component?.componentTemplate.componentNumber
        ?.toLowerCase()
        .replace(/[abcdefghijklmnopqrstuvwxyz]/g, "");
    }
    // Type 2 :  IComponent interface
    if (type === 2) {
      A = a?.componentTemplate.componentNumber
        ?.toLowerCase()
        .replace(/[abcdefghijklmnopqrstuvwxyz]/g, "");
      B = b?.componentTemplate.componentNumber
        ?.toLowerCase()
        .replace(/[abcdefghijklmnopqrstuvwxyz]/g, "");
    }


    
    // If number
    if (Number(A) && Number(B)) {
      if (Number(A) < Number(B)) {
        return -1;
      }
      if (Number(A) > Number(B)) {
        return 1;
      }
      return 0;
    }
    // If string
    else {
      if (A < B) {
        return -1;
      }
      if (A > B) {
        return 1;
      }
      return 0;
    }
  });
  return tempData;
}


export function SortComponentsById(data: any[]) {

  let tempData = [...data];
  tempData = tempData.sort((a: any, b: any) => {
    let A: number ;
    let B: number;
      A = a?.component?.Id
      B = b?.component?.Id

    if (A && B) {
      if (A < B) {
        return -1;
      }
      if (A > B) {
        return 1;
      }
      return 0;
    }
    // If string
    else {
      if (A < B) {
        return -1;
      }
      if (A > B) {
        return 1;
      }
      return 0;
    }
  });
  return tempData;
}

export function SortInspectionComponents(data: any[], type: number) {
  if (type !== 1 && type !== 2) return data;
  let tempData = [...data];
  tempData = tempData.sort((a: any, b: any) => {
    let A: string = "";
    let B: string = "";
    // Type 1 :  Images data
    if (type === 1) {
      A = a?.component?.componentTemplate.componentNumber
        ?.toLowerCase()
        .replace(/[abcdefghijklmnopqrstuvwxyz]/g, "");
      B = b?.component?.componentTemplate.componentNumber
        ?.toLowerCase()
        .replace(/[abcdefghijklmnopqrstuvwxyz]/g, "");
    }
    // Type 2 :  IComponent interface
    if (type === 2) {
      A = a?.componentTemplate.componentNumber
        ?.toLowerCase()
        .replace(/[abcdefghijklmnopqrstuvwxyz]/g, "");
      B = b?.componentTemplate.componentNumber
        ?.toLowerCase()
        .replace(/[abcdefghijklmnopqrstuvwxyz]/g, "");
    }



    // If number
    if (Number(A) && Number(B)) {
      if (Number(A) < Number(B)) {
        return -1;
      }
      if (Number(A) > Number(B)) {
        return 1;
      }
      return 0;
    }
    // If string
    else {
      if (A < B) {
        return -1;
      }
      if (A > B) {
        return 1;
      }
      return 0;
    }
  });
  return tempData;
}




